import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Home from '../scenes/Home';
import HeadMeta from '../components/HeadMeta';


const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Cloud Web Applications for Construction`,
    description: "Structural Analysis, BIM, Steel, Concrete or Wood design directly online"
  },
  fr: {
    title: `TriDyme | Applications Cloud pour la Construction`,
    description: "Calculs de Structure, BIM, Construction en Béton, Bois, Acier, et bien d'autres directement en ligne"
  }
};


const LandingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Home 
      locale={locale}
    />
  </Layout>
)

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LandingPage;
